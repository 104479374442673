<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
  >
    <v-row>
      <v-col>
        <h3>
          Enviar una notificacion a los usuarios de la APP
        </h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <v-text-field
          v-model="title"
          label="Ingrese el titulo"
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-text-field
          v-model="body"
          label="Ingrese el mensaje"
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-text-field
          v-model="link"
          label="Ingrese el link"
        />
      </v-col>
      <!-- <v-col
        cols="12"
        md="6"
      >
        <v-select
          v-model="topic"
          label="Seleccione el tema de la notificacion"
          :items="topics"
        />
      </v-col> -->
    </v-row>
    <v-row>
      <v-col>
        <v-btn
          color="primary"
          @click="send"
        >
          Enviar
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    data () {
      return {
        title: null,
        body: null,
        link: null,
        topic: 'all',
        topics: [],
      }
    },
    methods: {
      async send () {
        const payload = {
          title: this.title,
          body: this.body,
          link: this.link,
          topic: this.topic,
        }
        try {
          await this.$api.Notification.send(payload)
          this.title = ''
          this.body = ''
          this.link = ''
          this.$swal.fire('Notificaciones push', 'Se ha enviado la notificacion', 'success')
        } catch {
          this.$swal.fire('Notificaciones push', 'Ha ocurrido un error', 'error')
        }
      },
    },
  }
</script>
